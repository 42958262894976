import React, { useEffect, useState, Suspense } from "react";
import ContentBox from "../components/contents/ContentBox";
import LoadingIndicator from "../components/LoadingIndicator";
import PageWrapper from "../components/PageWrapper";
import configs from "../configs.json";
import { getData } from "../FatchApi/FatchApi";
const Customer = React.lazy(() => import("../components/Customer"));

const CustomerListPage = () => {
  const [customerList, setCustomerList] = useState([]);

  const getAllUsers = async () => {
    const res = await getData("api/users/get-users");

    setCustomerList(res?.users);
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <PageWrapper>
      <ContentBox name="Customer List" showSearch>
        <div className="w-full border-b border-b-glitch-box" />
        <Suspense fallback={<LoadingIndicator />}>
          {/* Customer list */}
          <ul className="px-5 pb-5">
            {customerList?.map(({ username, _id }, i) => (
              <li className="block mt-5" key={i}>
                <Customer name={username} userId={_id} />
                {/* <Customer name={name} orderCount={orderCount} /> */}
              </li>
            ))}
          </ul>
        </Suspense>
      </ContentBox>
    </PageWrapper>
  );
};

export default CustomerListPage;
