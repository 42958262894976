import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toastify = ({ close }) => {
  return (
    <>
      <ToastContainer autoClose={close} />
    </>
  );
};

export default Toastify;
