import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import ContentBox from "../components/contents/ContentBox";
import PageWrapper from "../components/PageWrapper";
import OrderDetailsProduct from "../components/products/OrderDetailsProduct";
import { BaseURL, getData, putData2 } from "../FatchApi/FatchApi";
import { useParams } from "react-router-dom";
import Toastify from "../components/Toast/Toastify";
import { toast } from "react-toastify";

const OrderDetailsPage = () => {
  const { orderId } = useParams();

  const [orderStatus, setOrderStatus] = useState("");
  const [orderStatusValue, setOrderStatusValue] = useState([]);
  const [singleOrder, setSingleOrder] = useState("");
  const [shipToken, setShipToken] = useState("");
  const [formData, setFormData] = useState({
    length: "",
    width: "",
    height: "",
    weight: "",
  });

  const GenerateTokenShiprocket = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${BaseURL}/api/shiprocket`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ body: " " }),
      });
      const data = await response.json();
      localStorage.setItem("shipToken", data.token);
    } catch (error) {
      toast.error("Failed to generate Shiprocket token", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getOrderStatusValue = async () => {
    const res = await getData("api/orders/get-order-status");
    setOrderStatusValue(res);
  };

  const getSingleOrder = async () => {
    const res = await getData(`api/orders/get-single-order/${orderId}`);

    if (res.status === "success") {
      setSingleOrder(res?.Orders ? res?.Orders[0] : "");
    }
  };

  useEffect(() => {
    getSingleOrder();
    getOrderStatusValue();
    GenerateTokenShiprocket();
    var Shiprotoken = localStorage.getItem("shipToken");
    setShipToken(Shiprotoken);
  }, []);

  const address = singleOrder?.deliveryAddress?.address;
  const handleChange = (event) => {
    setOrderStatus(event.target.value);
  };

  const updateStatus = async () => {
    const body = {
      orderstatus: orderStatus,
    };
    const data = JSON.stringify(body);
    const ContentType = "application/json";
    const res = await putData2(`api/orders/update-order-status/${orderId}`, {
      data,
      ContentType,
    });
    if (res?.success) {
      toast.success("Order status updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      getSingleOrder();
      getOrderStatusValue();
    } else {
      toast.error("Order status not updated");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the weight and height of package for shiprocket
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //order sending on shiprocket

    if (singleOrder?.shipmentId == "Waiting for order accept") {
      if (
        formData.height !== "" &&
        formData.length !== "" &&
        formData.weight !== "" &&
        formData.width !== ""
      ) {
        const token = localStorage.getItem("shipToken");
        const data = await fetch(`${BaseURL}/api/shiprocket/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ orderId, formData }),
        });

        const res = await data.json();

        //Saving  shiprocket shipment Id and ship orderid in user order data base
        if (res?.success == true) {
          const data = JSON.stringify({
            shipmentId: res.data.shipment_id,
            shipOrderId: res.data.order_id,
          });

          const ContentType = "application/json";
          const orderres = await putData2(
            `api/orders/update-order/${orderId}`,
            { data, ContentType }
          );

          if (orderres.success) {
            setFormData({ length: "", width: "", height: "", weight: "" });
            getSingleOrder();
            toast.success("Order and Shipment ID updated in user Database ", {
              position: toast.POSITION.TOP_LEFT,
            });
          } else {
            toast.error(
              "Order and Shipment ID  not updated in user Order Database",
              {
                position: toast.POSITION.TOP_LEFT,
              }
            );
          }
        } else {
          toast.error(res?.data, {
            position: toast.POSITION.TOP_LEFT,
          });
        }
      } else {
        toast.error("Please fill the details For Shiprocket", {
          position: toast.POSITION.TOP_LEFT,
        });
      }
    } else {
      toast.error("The order has already been sent to ShipRocket", {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };
  console.log(singleOrder);
  return (
    <PageWrapper>
      <ContentBox name={`Order Id: ${orderId}`}>
        <Toastify close={"2000"} />
        <div className="border-b border-b-glitch-box"></div>
        <div className="px-5 py-5">
          {/* Ordered products */}
          <OrderDetailsProduct
            items={singleOrder?.items}
            price={111}
            units={2}
            quantity={1}
            image=""
          />
          <div className="mt-4">
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Item Total</span>
              <span>₹{singleOrder?.orderSummary?.cartTotal}</span>
            </div>

            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Discount on Purchase</span>
              <span>
                -₹
                {singleOrder?.orderSummary?.discountOnPurchase}
              </span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Coupon</span>
              <span>{singleOrder?.orderSummary?.couponeCode}</span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Total after Discount</span>
              <span>₹{singleOrder?.orderSummary?.totalAfterDiscount}</span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Delivery</span>

              <span>
                {singleOrder?.orderSummary?.deliveryCharges == "Free"
                  ? "Free"
                  : "+₹" + singleOrder?.orderSummary?.deliveryCharges}
              </span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Grand Total (Inc.of Taxes and Delivery Charge)</span>
              <span className="text-green-300">
                ₹{singleOrder?.orderSummary?.totalAfterDiscountAndDeliverCharge}
              </span>
            </div>
          </div>

          <hr className="w-full border-b border-b-glitch-box my-5" />

          {/* Customer details */}
          <div className="mt-4">
            <div className="text-white flex items-center justify-between">
              <div className="font-medium">CUSTOMER DETAILS</div>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Name</span>
              <span>{address?.name}</span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-white">
              <span>Mobile</span>
              <div>
                <span>{address?.mobile_Number} </span>
                <button className="material-icons-outlined text-lg px-1 bg-blue-500 text-white rounded">
                  phone
                </button>
              </div>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span>Address</span>
              <span>
                {address?.locality},{address?.city},{address?.state}
              </span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span>Pin Code</span>
              <span>{address?.pincode}</span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span>City</span>
              <span>{address?.city}</span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span>Payment</span>
              <Button>
                <span className="text-yellow-400 px-1">
                  {" "}
                  {singleOrder?.isPaid ? "Paid" : "Cash on Delivery"}
                </span>
              </Button>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span> Order Status</span>
              <Button>
                <span className="text-yellow-400 px-1">
                  {" "}
                  {singleOrder?.orderstatus}
                </span>
              </Button>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span>Shiprocket OrderId</span>

              <span className="text-yellow-400 px-1">
                {" "}
                {singleOrder?.shipOrderId}
              </span>
            </div>
            <div className="flex items-center justify-between border-b border-b-glitch-box py-2 text-base text-white">
              <span>Shipment Id</span>

              <span className="text-yellow-400 px-1">
                {" "}
                {singleOrder?.shipmentId}
              </span>
            </div>
          </div>

          {/* Order actions */}
          <div class="flex mb-3">
            {/* <Button className="hover:bg-rose-500">
                <span className="material-icons text-lg mr-2 py-1">close</span>
                <span>Reject</span>
              </Button> */}
            <select
              id="countries"
              class="bg-gray-50 h-10 px-6  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={orderStatus}
              onChange={handleChange}
            >
              <option selected> Update Order Status </option>
              {orderStatusValue?.map((value) => {
                return (
                  <>
                    <option value={value}>{value}</option>
                  </>
                );
              })}
            </select>
            <div class="group relative mx-5">
              <button
                className="h-10 px-6  font-semibold rounded-xl bg-red hover:bg-indigo-500 text-white"
                onClick={updateStatus}
              >
                Update
              </button>
            </div>
            <div>
              <button
                disabled={singleOrder?.orderstatus !== "Shipped"}
                onClick={GenerateTokenShiprocket}
                className="h-10 px-6  font-semibold rounded-xl text-sm bg-red hover:bg-indigo-500 text-white"
              >
                Verify Shipro
              </button>
            </div>
          </div>
          {shipToken &&
          singleOrder?.orderstatus === "Shipped" &&
          singleOrder?.shipmentId === "Waiting for order accept" ? (
            <div>
              {" "}
              Please fill these details for Shiprocket
              <form
                encType="multipart/form-data"
                onSubmit={handleSubmit}
                className="grid grid-cols-2 gap-4 mx-auto"
              >
                <div className="mb-4">
                  <label htmlFor="length" className="block mb-1 font-medium">
                    Length in cm
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded"
                    id="length"
                    name="length"
                    value={formData.length}
                    onChange={(e) => handleInputChange(e)}
                    required
                    placeholder="Package Length in (0.0)"
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="breadth" className="block mb-1 font-medium">
                    Breadth in cm
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded"
                    id="breadth"
                    name="width"
                    value={formData.width}
                    onChange={(e) => handleInputChange(e)}
                    required
                    placeholder="Package Width (0.0)"
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="height" className="block mb-1 font-medium">
                    Height in cm
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded"
                    id="height"
                    name="height"
                    value={formData.height}
                    onChange={(e) => handleInputChange(e)}
                    required
                    placeholder="Package Height (0.0)"
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="weight" className="block mb-1 font-medium">
                    Weight in Kg
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded"
                    id="weight"
                    name="weight"
                    value={formData.weight}
                    onChange={(e) => handleInputChange(e)}
                    required
                    placeholder="Package Weight (0.0)"
                  />
                </div>
                <div className="text-center">
                  <button
                    className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                    type="submit"
                  >
                    Save Product
                  </button>
                </div>
              </form>{" "}
            </div>
          ) : (
            <h3 className="text-white">
              {" "}
              {singleOrder?.shipOrderId == "Waiting for order accept" ? (
                " Please Ship Order for Shiprocket"
              ) : (
                <a
                  className="text-blue"
                  href={`https://app.shiprocket.in/seller/orders/details/${singleOrder?.shipOrderId}`}
                >
                  <u> Click here to see Order Shiprocket</u>
                </a>
              )}{" "}
            </h3>
          )}
        </div>
      </ContentBox>
    </PageWrapper>
  );
};

export default OrderDetailsPage;
