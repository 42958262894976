import React, { useState } from "react";
import { postData } from "../FatchApi/FatchApi";
import PageWrapper from "../components/PageWrapper";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Loader/Spinner";
import HeroBanner from "../components/Banners Uplaod/HeroBanner";

const UpdateImage = () => {
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [productData, setProductData] = useState({
    heroBanner: "",
    homePramotionBanner: "",
    homeGiftBanner: "",
    contactUsBanner: "",
    aboutUsBanner: "",
  });

  const handleSingleFileChange = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;

    setProductData((prevData) => ({
      ...prevData,
      [name]: file,
    }));
  };

  const handleMultiFileChange = (e) => {
    const files = e.target.files;
    const fileArray = Array.from(files);
    const name = e.target.name;
    const fileURLs = fileArray.map((file) => file[0]);
    setProductData((prevData) => ({
      ...prevData,
      [name]: fileArray,
      imageUrls: fileURLs,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    const contanttype = "";

    const formData = new FormData();
    if (productData.heroBanner.length !== 0) {
      productData.heroBanner.forEach((image, index) => {
        formData.append(`heroBanner`, image);
      });
    }

    if (productData.homePramotionBanner !== "") {
      formData.append("homePramotionBanner", productData.homePramotionBanner);
    }
    if (productData.homeGiftBanner !== "") {
      formData.append("homeGiftBanner", productData.homeGiftBanner);
    }
    if (productData.aboutUsBanner !== "") {
      formData.append("aboutUsBanner", productData.aboutUsBanner);
    }
    if (productData.contactUsBanner !== "") {
      formData.append("contactUsBanner", productData.contactUsBanner);
    }
    const res = await postData("api/images/images-upload", {
      data: formData,
      contanttype,
    });
    console.log(res);
    if (res.success) {
      setSpinner(false);
      navigate("/manage-images");
    } else {
      setSpinner(false);
      alert(res.message);
    }
  };

  return (
    <>
      {spinner ? <Spinner /> : ""}
      <PageWrapper>
        <HeroBanner />
      </PageWrapper>
    </>
  );
};

export default UpdateImage;
