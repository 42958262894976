import React, { useEffect, useState } from "react";
import PageWrapper from "../components/PageWrapper";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
import { getData } from "../FatchApi/FatchApi";

const ManageReviews = () => {
  const [reviews, setReviews] = useState([]);

  const getReviews = async () => {
    const res = await getData("api/review/get-all-reviews");

    setReviews(res.Reviews);
  };
  useEffect(() => {
    getReviews();
  }, []);
  return (
    <>
      <PageWrapper>
        <div className="container mx-auto ">
          <h1 className="text-white  text-xl">
            Customer Reviews: {reviews?.length}
          </h1>
          {reviews?.map((data) => (
            <>
              <div className="mt-2 shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                <div className=" flex justify-between">
                  <div className="flex flex-row pt-5">
                    <div className="">
                      <img
                        className="h-10 w-10 rounded-full mx-3 "
                        src="https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_1280.png"
                      />
                    </div>
                    <div className="pt-1">
                      <p>{data?.userId?.username}</p>
                      <p>{new Date(data?.createdAt).toLocaleString()}</p>
                      <ReactStars
                        count={"5"}
                        value={data?.ratingStars}
                        size={25}
                        activeColor="#ffd700"
                        edit={false}
                      />
                    </div>
                  </div>
                  <div className="pt-7">
                    <Link
                      to="view-review"
                      state={{ data: data }}
                      className=""
                      role="button"
                    >
                      <img
                        src="../../../view.png"
                        className="h-7 w-7 text-blue"
                      />
                    </Link>
                    <p className="mr-5  my-3">
                      Approvement :
                      <span
                        style={{
                          color:
                            data?.approvement == "pending"
                              ? "yellow"
                              : data?.approvement == "approved"
                              ? "green"
                              : "red",
                        }}
                      >
                        {data?.approvement}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex p-5">
                  <p className="text-white">{data?.reviewComment}</p>
                </div>
              </div>
            </>
          ))}
        </div>
      </PageWrapper>
    </>
  );
};

export default ManageReviews;
