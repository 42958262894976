import React, { useState } from "react";
import "./Loginpagestyle.css";
import { postData } from "../FatchApi/FatchApi";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();
  const [signUp, setSignUp] = useState(false);
  const [loginData, setLoginData] = useState({ username: "", password: "" });
  const [signupData, setSignupData] = useState({
    username: "",
    email: "",
    password: "",
    number: "",
  });

  const toggleSignUp = () => {
    setSignUp(!signUp);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (signUp) {
      const data = JSON.stringify(signupData);
      const contanttype = "application/json";
      const res = await postData("8888", {
        data,
        contanttype,
      });
      if (res.success) {
        const email = res?.data?.emailsaved;
        navigate("/verify-otp", {
          state: {
            email: email,
          },
        });
        // const Token = res.data.accessToken;
        // localStorage.setItem("token", Token);
        // window.location.reload();
      }
    } else {
      if (navigator.onLine) {
        const body = {
          email: loginData?.username,
          password: loginData?.password,
        };

        const data = JSON.stringify(body);
        const contanttype = "application/json";
        const res = await postData("api/auth/login", { data, contanttype });
        if (res.success) {
          const Token = res.data.accessToken;
          localStorage.setItem("token", Token);

          if (res?.data?.isAdmin) {
            navigate("/Dashboard");
            window.location.reload();
          } else {
            alert("Please Login with Admin Email and Password");
          }
        }
        alert(res.message);
      } else {
        alert("connect to internet");
      }
    }
  };

  const renderInputs = (inputs, data) => {
    return inputs.map((input) => (
      <div className={input.show ? "field field-in" : "field"} key={input.id}>
        <label className="label">
          {input.label}
          <i
            className={input.validated ? "fa fa-check animate-check" : ""}
            aria-hidden="true"
          ></i>
        </label>
        <br />
        <input
          className="input"
          type={input.type}
          // onBlur={validateField}
          id={input.id}
          value={data[input.id]}
          onChange={(e) => handleChange(input.id, e.target.value)}
        />
      </div>
    ));
  };

  const handleChange = (field, value) => {
    if (signUp) {
      setSignupData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    } else {
      setLoginData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };

  const loginInputs = [
    {
      label: "User Email",
      type: "text",
      show: true,
      validated: false,
      id: "username",
    },
    {
      label: "Password",
      type: "password",
      show: true,
      validated: false,
      id: "password",
    },
  ];

  const signupInputs = [
    {
      label: "Name",
      type: "text",
      show: false,
      validated: false,
      id: "username",
    },
    {
      label: "Email",
      type: "email",
      show: false,
      validated: false,
      id: "email",
    },
    {
      label: "Password",
      type: "password",
      show: false,
      validated: false,
      id: "password",
    },
    {
      label: "Number",
      type: "text",
      show: false,
      validated: false,
      id: "number",
    },
  ];

  return (
    <div>
      <div className={signUp ? "login login-closed" : "login"}>
        <h1>Log In</h1>
        <hr />
        <form onSubmit={submitForm}>
          {renderInputs(loginInputs, loginData)}
          <div>
            <hr />
            <button className="submit-button" type="submit">
              Submit
            </button>
          </div>
        </form>
        <div className="signup-link">
          {/* <p className="in-out">
            Don't have an account? <a onClick={toggleSignUp}>Sign Up Here</a>
          </p> */}
        </div>
      </div>

      <div className={signUp ? "sign-up" : "sign-up sign-up-closed"}>
        <h1>Sign Up</h1>
        <hr />
        <form onSubmit={submitForm}>
          {renderInputs(signupInputs, signupData)}
          <div>
            <hr />
            <button className="submit-button" type="submit">
              Submit
            </button>
          </div>
        </form>
        <div className="signup-link">
          <p className="in-out">
            {/* Already have an account?{" "} */}
            <a onClick={toggleSignUp}>Log In Here</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
