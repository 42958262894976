import React from "react";

const Offerbadge = ({ offer, name, item }) => {
  return (
    <>
      <div
        role="alert"
        className="max-w-[300px] px-2 py-1 bg-indigo-800 rounded-full items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
      >
        <span className="flex rounded-full bg-red-500 uppercase text-[12px] px-2 py-1  font-bold mr-3 text-white">
          {name}
        </span>
        <span className="font-semibold mr-2 text-left flex-auto text-[11px] text-white">
          {offer?.type}
        </span>
        {item ? (
          <svg
            clip-rule="evenodd"
            fill-rule="evenodd"
            height="18"
            image-rendering="optimizeQuality"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            viewBox="0 0 2.54 2.54"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="图层_x0020_1">
              <circle
                cx="1.27"
                cy="1.27"
                fill={item?.qty >= offer?.productsQty ? "#00ba00" : "#C0C0C0"}
                r="1.27"
              />
              <path
                d="m.87344 1.88938-.46448-.49713c-.06359-.06806-.05994-.17574.00812-.23933.06806-.06359.17574-.05993.23933.00812l.35828.38346.56711-.52987c.0051-.00477.01043-.00914.01594-.01316l.26669-.24917c.06806-.06359.17575-.05993.23933.00813.06359.06805.05993.17574-.00812.23933l-.81454.76105-.00087-.00093-.28176.26326-.12503-.13382z"
                fill="#fff"
              />
            </g>
          </svg>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Offerbadge;
