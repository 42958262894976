import React, { useState } from "react";
import { BaseURL, postData } from "../FatchApi/FatchApi";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

const RecipeAddForm = ({ id }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [description, setDescription] = useState("");

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);

      fetch(`${BaseURL}/api/upload`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data.url);
          resolve({ data: { link: `${BaseURL}${data.url}` } });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const { blocks } = convertToRaw(editorState.getCurrentContent());
  };

  const convertContentToHTML = () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const html = draftToHtml(rawContentState);
    setDescription(html);
  };
  const initialFormData = {
    name: id.name ? id.name : "",
    preprationTime: id.preparation_time ? id.preparation_time : "",
    cookingTime: id.cooking_time ? id.cooking_time : "",
    servings: id.servings ? id.servings : "",
    paragraph: id.paragraph ? id.paragraph : "",
    tags: id.tags ? id.tags.split(",") : "",
    metaTitle: id.metaTitle ? id.metaTitle : "",
    metaDescription: id.metaDescription ? id.metaDescription : "",
    metaKeywords: id.metaKeywords ? id.metaKeywords : "",
    images: "",
  };

  const [recipeData, setRecipeData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRecipeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const fileArray = Array.from(files);
    const fileURLs = fileArray.map((file) => file[0]);
    setRecipeData((prevData) => ({
      ...prevData,
      images: fileArray,
      imageUrls: fileURLs,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(recipeData.tags.split(","));

    const contanttype = "";
    const formData = new FormData();
    formData.append("name", recipeData.name);
    formData.append("preparation_time", recipeData.preprationTime);
    formData.append("slug", recipeData.slug);
    formData.append("paragraph", recipeData.paragraph);
    formData.append("metaTitle", recipeData.metaTitle);
    formData.append("metaDescription", recipeData.metaDescription);
    formData.append("metaKeywords", recipeData.metaKeywords);
    formData.append("cooking_time", recipeData.cookingTime);
    formData.append("servings", recipeData.servings);
    formData.append("tags", recipeData.tags);
    formData.append("description", description);
    recipeData.images.forEach((image, index) => {
      formData.append(`images`, image);
    });
    if (id) {
      const Id = id._id;
      console.log(formData, "this is update api");
      const res = "";
      //console.log(res, " update", Id, formData);
      // Reset form data
      if (res && res.status === "success") {
        // setProductData(initialFormData1);
        alert(res?.message);
      } else {
        alert(res?.message);
      }
    } else {
      console.log("aadd blog");
      const res = await postData("api/recipe/upload-recipe", {
        data: formData,
        contanttype,
      });
      console.log(res);
      if (res.success) {
        window.location.reload();
      } else {
        alert(res.message);
      }
    }
  };

  return (
    <>
      <form encType="multipart/form-data" onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4 mx-auto">
          <div className="mb-4">
            <label htmlFor="title" className="block mb-1 font-medium">
              Recipe Name
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              id="name"
              placeholder="name"
              name="name"
              value={recipeData.name}
              onChange={handleChange}
              required
            />
          </div>

          {/* <div className="mb-4">
        <label htmlFor="productDescription" className="block mb-1 font-medium">
          Blog Description
        </label>
        <textarea
          className="w-full px-3 py-2 border border-gray-300 rounded bg-light"
          rows="3"
          placeholder="Enter product description"
          id="productDescription"
          name="description"
          value={productData.description}
          onChange={handleChange}
          required
        ></textarea>
      </div> */}

          <div className="mb-4">
            <label htmlFor="sku" className="block mb-1 font-medium">
              Recipe Paragraph
            </label>
            <input
              type="text"
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="Paragraph "
              id="sku"
              name="paragraph"
              value={recipeData.paragraph}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="metaTitle" className="block mb-1 font-medium">
              Meta Title
            </label>
            <input
              type="text"
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="Paragraph "
              id="metaTitle"
              name="metaTitle"
              value={recipeData.metaTitle}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="metaDescription" className="block mb-1 font-medium">
              Meta Description
            </label>
            <input
              type="text"
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="Paragraph "
              id="metaDescription"
              name="metaDescription"
              value={recipeData.metaDescription}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="metaKeywords" className="block mb-1 font-medium">
              Meta Keywords
            </label>
            <input
              type="text"
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="Paragraph "
              id="metaKeywords"
              name="metaKeywords"
              value={recipeData.metaKeywords}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="mrp" className="block mb-1 font-medium">
              Prepration Time
            </label>
            <input
              type="text"
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="Prepration Time"
              id=""
              name="preprationTime"
              value={recipeData.preprationTime}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="mrp" className="block mb-1 font-medium">
              Cooking Time
            </label>
            <input
              type="text"
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="Cooking Time"
              id="cookingTime"
              name="cookingTime"
              value={recipeData.cookingTime}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="mrp" className="block mb-1 font-medium">
              Servings
            </label>
            <input
              type="text"
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="Servings"
              id="servings"
              name="servings"
              value={recipeData.servings}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="mrp" className="block mb-1 font-medium">
              Recipe Slug
            </label>
            <input
              type="text"
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="  Slug "
              id="ean"
              name="slug"
              value={recipeData.slug}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="mrp" className="block mb-1 font-medium">
              Tags
            </label>
            <input
              type="text"
              step="1"
              className="w-full px-3 py-2 border border-gray-300 rounded"
              placeholder="  tags"
              id="ean"
              name="tags"
              value={recipeData.tags}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="image" className="block mb-1 font-medium">
              Image
            </label>

            <input
              type="file"
              id="images"
              name="images"
              onChange={handleFileChange}
              multiple
            />
          </div>
        </div>
        <div className="mb-4 bg-white">
          <Editor
            editorState={editorState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            onEditorStateChange={onEditorStateChange}
            toolbar={{
              image: {
                uploadCallback: uploadImageCallBack,
              },
            }}
          />
          <button
            className="px-4 py-2 mt-5  text-white bg-blue-500 rounded hover:bg-blue-600 float-right text-center"
            onClick={convertContentToHTML}
          >
            Upload Recipes
          </button>
        </div>
      </form>
    </>
  );
};

export default RecipeAddForm;
