import React, { useEffect, useState, lazy, Suspense } from "react";
import ContentBox from "../components/contents/ContentBox";
import LoadingIndicator from "../components/LoadingIndicator";
import PageWrapper from "../components/PageWrapper";
import SortByTag from "../components/SortByTag";
import ProductAddForm from "../components/addProduct";
import { getData } from "../FatchApi/FatchApi";
import Search from "../components/Search";

const Product = lazy(() => import("../components/products/Product"));

const ProductPage = () => {
  const [show, setshow] = useState(true);
  const [productList, setProductList] = useState([]);
  const [AllPorduct, setAllProduct] = useState([]);
  const [editID, setEditID] = useState(null);

  // Fetch products from server
  const getProduct = async () => {
    const res = await getData("api/products/get-products");
    if (res.status === "success") {
      setProductList(res.data);
    }
  };

  const searchProduct = (e) => {
    const { value } = e.target;
    const filterData = productList.filter((item) => {
      return item.title.toLowerCase().includes(value.toLowerCase());
    });

    setAllProduct(filterData);
  };

  useEffect(() => {
    getProduct();
  }, []);

  const openhandler = () => {
    setshow(!show);
    setEditID(false);
  };
  console.log(AllPorduct, productList);
  return (
    <PageWrapper>
      <div className="w-full">
        {show ? (
          <div style={{ display: "flex" }}>
            <div>
              <h1 className="text-lg text-gray-100 font-bold leading-5">
                Sapphire Dry Nuts Products
              </h1>
              <p className="text-gray-300 leading-5">
                Enhance your business by sharing your store URL.
              </p>
            </div>
            <div className="text-right" style={{ marginLeft: "auto" }}>
              <button
                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                onClick={() => openhandler()}
              >
                Add Product +
              </button>
            </div>
          </div>
        ) : (
          <div className="text-right">
            <button
              className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
              onClick={() => setshow(!show)}
            >
              Show All Products
            </button>
          </div>
        )}

        {!show ? (
          <ProductAddForm id={editID}></ProductAddForm>
        ) : (
          <ContentBox name="Active Products">
            <div className="flex justify-end">
              <input
                type="text"
                placeholder="Search your porduct"
                className="px-2 py-2  text-gray-200 w-full absolute left-0 top-1 bg-glitch-box"
                onChange={searchProduct}
              />
            </div>
            <>
              <div className="border-b border-b-glitch-box">
                <SortByTag tags={[]} />
              </div>

              {/* Products list */}
              <div className="mt-5"></div>
              <Suspense fallback={<LoadingIndicator />}>
                <ul className="sm:px-5 px-1 bg-transparent">
                  {AllPorduct.length !== 0
                    ? AllPorduct.map((res, i) => (
                        <li
                          className="block mt-4 pb-4 border-b border-b-glitch-box last:border-none"
                          key={i}
                        >
                          <Product
                            data={res}
                            onchange={getProduct}
                            setshow={setshow}
                            setEditID={setEditID}
                          />
                        </li>
                      ))
                    : productList.map((res, i) => (
                        <li
                          className="block mt-4 pb-4 border-b border-b-glitch-box last:border-none"
                          key={i}
                        >
                          <Product
                            data={res}
                            onchange={getProduct}
                            setshow={setshow}
                            setEditID={setEditID}
                          />
                        </li>
                      ))}
                </ul>
              </Suspense>
            </>
          </ContentBox>
        )}
      </div>
    </PageWrapper>
  );
};

export default ProductPage;
