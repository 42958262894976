import React, { useState, useEffect } from "react";
import PageWrapper from "../components/PageWrapper";

import Slider from "react-slick";

// Import slick-carousel CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BaseURL, getData } from "../FatchApi/FatchApi";

import { Link } from "react-router-dom";
import axios from "axios";
import Spinner from "../components/Loader/Spinner";

const ManageImages = () => {
  const [allImages, setAllImages] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [newBanners, setNewBanners] = useState({
    homePramotionBanner: "",
    homeGiftBanner: "",
    contactUsBanner: "",
    aboutUsBanner: "",
  });
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
  };

  const getImages = async () => {
    const res = await getData("api/images/get-all-images/allbanners");
    setAllImages(res.banners);
  };
  useEffect(() => {
    getImages();
  }, []);

  let BannerKeys = [];
  for (let key in newBanners) {
    if (key !== "heroBanner") {
      BannerKeys.push(key);
    }
  }

  const handleImageChange = (event, index) => {
    const file = event.target.files[0];
    const name = event.target.name;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setNewBanners((prev) => ({
        ...prev,
        [name]: reader.result,
      }));
    };
  };

  const uploadBanner = async (item) => {
    setSpinner(true);
    const res = await axios.post(`${BaseURL}/api/images/other-banner-upload`, {
      [item]: newBanners[item],
    });
    console.log(res.data.success);
    if (res.data?.success) {
      setNewBanners((prev) => ({
        ...prev,
        [item]: "",
      }));
      getImages();
      setSpinner(false);
    }
  };
  // console.log(BannerKeys);
  function splitCamelCase(str) {
    // Split the string where there's a capital letter and join with spaces
    const splitString = str.replace(/([a-z])([A-Z])/g, "$1 $2");

    // Capitalize the first letter of each word
    const capitalized = splitString.replace(/\b\w/g, (char) =>
      char.toUpperCase()
    );

    return capitalized;
  }
  const heroDemo = [
    "../camera-placeholder.png",
    "../camera-placeholder.png",
    "../camera-placeholder.png",
  ];
  const heroData = [];
  for (const key in allImages?.heroBanner) {
    heroData.push(key);
  }
  return (
    <PageWrapper>
      {spinner ? <Spinner /> : ""}
      <section className="bg-gradient-to-b from-gray-200 to-gray-400 bg-fixed h-full pt-5">
        <div className="container mx-auto px-4 w-full max-w-4xl">
          <div className="relative">
            <Slider {...settings}>
              {allImages?.heroBanner
                ? heroData.map((item, index) => (
                    <div className="relative group p-2" key={index}>
                      <Link to="/manage-images/hero-banner-change">
                        <img
                          src={allImages?.heroBanner[item]?.url}
                          className="object-cover w-full h-auto rounded-lg transition-transform duration-300 ease-in-out group-hover:scale-105"
                          alt="Best spices and masala brand in India"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          <div className="text-white text-lg font-semibold">
                            <h2 className="text-xl">
                              Hero Banner on Home page
                            </h2>
                            <p className="mt-2">Click On Banner to change it</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                : heroDemo.map((src, index) => (
                    <div className="relative group p-2" key={index}>
                      <Link to="/manage-images/hero-banner-change">
                        <img
                          src={src}
                          className="object-cover w-full h-auto rounded-lg transition-transform duration-300 ease-in-out group-hover:scale-105"
                          alt="Best spices and masala brand in India"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          <div className="text-white text-lg font-semibold">
                            <h2 className="text-xl">
                              Hero Banner on Home page
                            </h2>
                            <p className="mt-2">Click On Banner to change it</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
            </Slider>
          </div>
        </div>
      </section>

      <section className="bg-gradient-to-b from-gray-200 to-gray-400 bg-fixed h-full mt-5  ">
        <div className="container mx-auto ">
          <h1 className="text-center text-4xl text-white mb-2">
            Other Banners
          </h1>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
            {BannerKeys.map((item, i) => {
              return (
                <>
                  <div>
                    <h2 className=" my-2 text-[20px] font-bold text-white items-center  ">
                      {splitCamelCase(item)}
                    </h2>
                    <div className="relative group">
                      <img
                        src={
                          (newBanners && newBanners[item]) ||
                          (allImages && allImages[item]?.url) ||
                          "../camera-placeholder.png"
                        }
                        alt={`Gallery image`}
                        className="w-full h-auto object-cover rounded-lg shadow-md transition-transform duration-300 group-hover:scale-105"
                      />

                      <div className="inset-0 hidden group-hover:absolute group-hover:flex bg-black bg-opacity-50 items-center justify-center rounded-lg transition-opacity duration-300 ">
                        <label
                          for={`file${i}`}
                          role="button"
                          className="bg-white  text-gray-800 font-semibold py-2 px-4 rounded-full shadow-lg hover:bg-gray-100 transition-colors duration-300"
                        >
                          Change Image
                        </label>
                      </div>

                      <input
                        type="file"
                        name={item}
                        className="hidden"
                        id={`file${i}`}
                        accept="image/*"
                        onChange={(e) => handleImageChange(e, i)}
                      />
                    </div>
                    {newBanners[item] !== "" ? (
                      <>
                        {" "}
                        <div className="flex justify-center my-4">
                          <label
                            onClick={() => uploadBanner(item)}
                            for="uploadFile1"
                            class="flex bg-gray-800 hover:bg-gray-700 text-white text-base px-5 py-3 outline-none rounded w-max cursor-pointer mx-auto font-[sans-serif]"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="w-6 mr-2 fill-white inline"
                              viewBox="0 0 32 32"
                            >
                              <path
                                d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                                data-original="#000000"
                              />
                              <path
                                d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                                data-original="#000000"
                              />
                            </svg>
                            Upload
                          </label>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </PageWrapper>
  );
};

export default ManageImages;
